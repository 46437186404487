import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueMaplibreGl from '@indoorequal/vue-maplibre-gl'
import '~maplibre-gl/dist/maplibre-gl.css'

const app = createApp(App)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(createVuestic({ config: vuesticGlobalConfig }))

if (import.meta.env.VITE_APP_GOOGLE_MAP_API_KEY) {
  app.use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_APP_GOOGLE_MAP_API_KEY,
      libraries: 'places',
    },
  })
}

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.use(VueMaplibreGl)
app.mount('#app')
